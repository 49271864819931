import {getPostByArgument, isUserModerator} from "./dataUtils";
import * as Metrics from "./metrics";

function getMainConversationGraph(cif) {
    const conversationNode = cif['@graph'].find(item => item['@type'] === 'catalyst:Conversation');
    if (!conversationNode) {
        throw Error('CIF data does not contain conversation node.');
    }

    return cif['@graph'].find(item => item['@graph'] && item['@id'] === conversationNode.data_graph)['@graph'];
}

function getConversationHistoryGraph(mgraph, cif) {
    const historyGraphID = mgraph.find(item => item['history_graph']).history_graph;

    return cif['@graph'].find(item => item['@id'] === historyGraphID)['@graph'];
}

function CIFparser(cif) {
    const users = [];
    const posts = [];
    const args = [];
    const pros = [];
    const cons = [];
    const votes = [];
    const lemons = [];
    const events = [];
    let issue = null;

    const mgraph = getMainConversationGraph(cif); // main conversation graph
    const hgraph = getConversationHistoryGraph(mgraph, cif); // conversation history graph

    for (const item of mgraph) {
        switch (item['@type']) {
            case 'Issue':
                issue = item;
                break;

            case 'UserAccount':
                if (item['@id'] !== 'main_site:users/anonymous') {
                    users.push(item);
                }
                break;

            case 'BinaryVote':
                if (item.vote_subject_link) {
                    const subjectLink = mgraph.find(node => node['@id'] === item.vote_subject_link);

                    votes.push({
                        ...item,
                        postID: subjectLink.response_position,
                        argumentID: subjectLink.argument_arguing,
                    });
                } else {
                    lemons.push(item);
                }
                break;

            case 'Position':
                posts.push(item);
                break;

            case 'Argument':
                args.push(item);
                break;

            case 'ArgumentOpposesIdea':
                cons.push(item);
                break;

            case 'ArgumentSupportsIdea':
                pros.push(item);
                break;

            default:
        }
    }

    for (const item of hgraph) {
        if (!item['@type'] || !item['who']) {
            continue;
        }

        if (item.who !== 'main_site:users/anonymous') {
            events.push(item);
        }
    }

    return {
        raw: cif,
        issue,
        users,
        posts,
        arguments: args,
        pros,
        cons,
        votes,
        lemons,
        events
    }
}

function getUsersSortedByInvolvement(users, data) {
    const rows = [];

    for (const user of data.users) {
        rows.push({
            user,
            involvement: Number(Metrics.involvement(user['@id'], data).toFixed(2)),
        })
    }

    rows.sort((a, b) => b.involvement - a.involvement);

    return rows;
}

function CIFdataLimiter(fullData, userLimit) {
    if (!(userLimit > 0)) return fullData;

    const sortedUsers = getUsersSortedByInvolvement(fullData.users, fullData);
    const limitedUsersIds = sortedUsers.slice(0, userLimit).map(item => item.user['@id']);

    const users = [];
    const posts = [];
    const args = [];
    const pros = [];
    const cons = [];
    const votes = [];
    const lemons = [];
    const events = [];

    const rejectedPostsIds = [];
    const rejectedArgsIds = [];

    for (const user of fullData.users) {
        if (limitedUsersIds.includes(user['@id'])) {
            users.push(user);
        }
    }

    for (const post of fullData.posts) {
        if (limitedUsersIds.includes(post.has_creator)) {
            posts.push(post);
        } else {
            rejectedPostsIds.push(post['@id']);
        }
    }

    for (const arg of fullData.arguments) {
        const relatedPostId = getPostByArgument(arg['@id'], fullData)['@id'];
        if (limitedUsersIds.includes(arg.has_creator) && !rejectedPostsIds.includes(relatedPostId)) {
            args.push(arg);
        } else {
            rejectedArgsIds.push(arg['@id']);
        }
    }

    for (const pro of fullData.pros) {
        const relatedPostId = pro.reply_to;
        if (limitedUsersIds.includes(pro.has_creator) && !rejectedPostsIds.includes(relatedPostId) && !rejectedArgsIds.includes(pro.argument_arguing)) {
            pros.push(pro);
        }
    }

    for (const con of fullData.cons) {
        const relatedPostId = con.reply_to;
        if (limitedUsersIds.includes(con.has_creator) && !rejectedPostsIds.includes(relatedPostId) && !rejectedArgsIds.includes(con.argument_arguing)) {
            cons.push(con);
        }
    }

    for (const vote of fullData.votes) {
        const relatedArgumentId = vote.argumentID;
        const relatedPostId = vote.postID;
        if (limitedUsersIds.includes(vote.voter) && !rejectedArgsIds.includes(relatedArgumentId) && !rejectedPostsIds.includes(relatedPostId)) {
            votes.push(vote);
        }
    }

    // for (const lemon of fullData.lemons) {
    //     const relatedPostId = lemon.postID;
    //     if (limitedUsersIds.includes(lemon.voter) && !rejectedPostsIds.includes(relatedPostId)) {
    //         lemons.push(lemon);
    //     }
    // }

    for (const event of fullData.events) {
        if (limitedUsersIds.includes(event.who) && !rejectedPostsIds.includes(event.what) && !rejectedArgsIds.includes(event.what)) {
            events.push(event);
        }
    }

    return {
        raw: fullData.raw,
        issue: fullData.issue,
        users,
        posts,
        arguments: args,
        pros,
        cons,
        votes,
        lemons,
        events,
    }
}

export { CIFparser, CIFdataLimiter };
