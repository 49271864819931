import {
    getArgumentCreatorName, getPostByArgument,
    getPostCreatorName,
    getUserArguments,
    getUserCons,
    getUserPosts,
    getUserPros, getUserViews, getUserVotes
} from "./dataUtils";

function formatDate(datetimestr) {
    return new Date(datetimestr).toLocaleString();
}

function getRelationsData(data) {
    const columns = [
        {id: 'vertex_1', label: 'Vertex 1', numeric: false},
        {id: 'vertex_2', label: 'Vertex 2', numeric: false},
        {id: 'relationship', label: 'Relationship', numeric: false},
        {id: 'related_post', label: 'Related post', numeric: false},
        {id: 'related_content', label: 'Related content', numeric: false},
        {id: 'relationship_date', label: 'Relationship date', numeric: false},
    ];
    const rows = [];

    for (const v1 of data.users) {
        // Posts
        const userPosts = getUserPosts(v1['@id'], data.posts);
        for (const post of userPosts) {
            rows.push({
                vertex_1: v1.name,
                vertex_2: v1.name,
                relationship: 'Post',
                related_post: post['title']['@value'],
                related_content: post['description']?.['@value'],
                relationship_date: formatDate(post.created),
            })
        }

        // Pros
        const userPros = getUserPros(v1['@id'], data.pros);
        for (const pro of userPros) {
            const proRelatedPost = data.posts.find(post => post['@id'] === pro.reply_to);
            const argument = data.arguments.find(argument => argument['@id'] === pro.argument_arguing);
            rows.push({
                vertex_1: v1.name,
                vertex_2: data.users.find(user => user['@id'] === proRelatedPost.has_creator).name,
                relationship: 'Argument',
                related_post: proRelatedPost['title']['@value'],
                related_content: argument['description']?.['@value'],
                relationship_date: formatDate(pro.created),
            })
        }

        // Cons
        const userCons = getUserCons(v1['@id'], data.cons);
        for (const con of userCons) {
            const conRelatedPost = data.posts.find(post => post['@id'] === con.reply_to);
            const argument = data.arguments.find(argument => argument['@id'] === con.argument_arguing);
            rows.push({
                vertex_1: v1.name,
                vertex_2: data.users.find(user => user['@id'] === data.posts.find(post => post['@id'] === con.reply_to).has_creator).name,
                relationship: 'Argument',
                related_post: conRelatedPost['title']['@value'],
                related_content: argument['description']?.['@value'],
                relationship_date: formatDate(con.created),
            })
        }

        // Votes
        const userVotes = getUserVotes(v1['@id'], data.votes);
        for (const vote of userVotes) {
            const voteRelatedPost = vote.postID 
                ? data.posts.find(post => post['@id'] === vote.postID)
                : getPostByArgument(vote.argumentID, data);
            if (vote.postID || vote.argumentID) {
                rows.push({
                    vertex_1: v1.name,
                    vertex_2: vote.postID ? getPostCreatorName(vote.postID, data) : getArgumentCreatorName(vote.argumentID, data),
                    relationship: 'Vote',
                    related_post: voteRelatedPost['title']['@value'],
                    related_content: '',
                    relationship_date: formatDate(vote.created),
                })
            }
        }

        // Views
        const userViews = getUserViews(v1['@id'], data.events);
        for (const view of userViews) {
            
            const viewRelatedPost = data.posts.find(post => post['@id'] === view.what);
            const viewRelatedArgument = data.arguments.find(argument => argument['@id'] === view.what);
            
            if (viewRelatedPost) { // Is post view
                rows.push({
                    vertex_1: v1.name,
                    vertex_2: getPostCreatorName(view.what, data),
                    relationship: 'ViewPost',
                    related_post: viewRelatedPost['title']['@value'],
                    related_content: '',
                    relationship_date: formatDate(view.when),
                })
            }
            else if (viewRelatedArgument) { // Is argument view
                const argumentViewRelatedPost = getPostByArgument(viewRelatedArgument['@id'], data);
                rows.push({
                    vertex_1: v1.name,
                    vertex_2: getArgumentCreatorName(view.what, data),
                    related_post: argumentViewRelatedPost['title']['@value'],
                    related_content: '',
                    relationship: 'ViewArgument',
                    relationship_date: formatDate(view.when),
                })
            }
        }
    }


    return {
        rows,
        columns
    }
}

export default getRelationsData;
