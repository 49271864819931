import * as Metrics from './metrics';
import {isUserModerator} from './dataUtils';

function getUsersData(data) {
    const columns = [
        {id: 'name', label: 'Name', numeric: false},
        {id: 'reputation', label: 'Reputation', numeric: true},
        {id: 'involvement', label: 'Involvement [%]', numeric: true},
        {id: 'efficiency', label: 'Efficiency', numeric: true},
        {id: 'textsLength', label: 'Texts length', numeric: true, color: '#800000'},
        {id: 'edits', label: 'Edits', numeric: true, color: '#800000'},
        {id: 'i10tSupport', label: 'Inconsistent support', numeric: true},
        {id: 'conflict', label: 'Conflict', numeric: true},
        {id: 'densityOfSocialBounds', label: 'Density of social bonds', numeric: true, color: '#800000'},
        {id: 'intensityOfSocialBounds', label: 'Intensity of social bonds', numeric: true, color: '#800000'},
        {id: 'activity', label: 'Activity', numeric: true},
        {id: 'userViewsCount', label: 'Views Count', numeric: true, color: '#800000'},
        {id: 'userPostsCount', label: 'Posts Count', numeric: true, color: '#800000'},
        {id: 'userArgumentsCount', label: 'Arguments Count', numeric: true, color: '#800000'},
        {id: 'userVotesCount', label: 'Votes Count', numeric: true, color: '#800000'},
    ];
    const rows = [];

    for (const user of data.users) {
        rows.push({
            name: (user['name'] || user['@id'].substr(-7)) + (isUserModerator(user['@id'], data) ? ' ᴹᴼᴰᴱᴿᴬᵀᴼᴿ' : ''),
            reputation: Metrics.reputation(user['@id'], data),
            involvement: Number(Metrics.involvement(user['@id'], data).toFixed(2)),
            efficiency: Number(Metrics.efficiency(user['@id'], data).toFixed(2)),
            textsLength: Number(Metrics.userTextsLength(user['@id'], data).toFixed(2)),
            edits: Number(Metrics.userEdits(user['@id'], data).toFixed(2)),
            i10tSupport: Number(Metrics.inconsistentSupport(user['@id'], data).toFixed(2)),
            conflict: Number(Metrics.conflict(user['@id'], data).toFixed(2)),
            densityOfSocialBounds: Number(Metrics.densityOfSocialBounds(user['@id'], data).toFixed(2)),
            intensityOfSocialBounds: Number(Metrics.intensityOfSocialBounds(user['@id'], data).toFixed(2)),
            activity: Number(Metrics.activity(user['@id'], data).toFixed(2)),
            userViewsCount: Number(Metrics.userViewsCount(user['@id'], data).toFixed(2)),
            userPostsCount: Number(Metrics.userPostsCount(user['@id'], data).toFixed(2)),
            userArgumentsCount: `${Number(Metrics.userProsCount(user['@id'], data).toFixed(2))}↑ ${Number(Metrics.userProsCount(user['@id'], data).toFixed(2))}↓`,
            userVotesCount: `${Number(Metrics.userPositiveVotesCount(user['@id'], data).toFixed(2))}↑ ${Number(Metrics.userNegativeVotesCount(user['@id'], data).toFixed(2))}↓`,
            
        })
    }

    return {
        rows,
        columns
    }
}

export default getUsersData;
