import Papa from 'papaparse';
import { saveAs } from 'file-saver';

export function exportCSV(columns, rows, title) {
    const csvHeader = Papa.unparse({fields: columns.map(item => item.label) });
    const csvBody = Papa.unparse(rows, { columns: columns.map(item => item.id), header: false });
    const dataString = csvHeader + '\n' + csvBody;
    const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${title || 'Data'}.csv`);
}
