import {
    isUserModerator,
    getVoteByUserAndPost,
    getVoteByUserAndArgument, 
    getProsCountByUserAndPost,
    getConsCountByUserAndPost,
} from './dataUtils';
import {ncdf} from "./stat";

function getPostsData(data, normalization = false) {
    const columns = [
        {id: 'post', label: 'Title', numeric: false},
    ];

    for (const user of data.users) {
        columns.push({
            id: user['@id'],
            label: (user['name'] || user['@id'].substr(-7)) + (isUserModerator(user['@id'], data) ? ' ᴹᴼᴰᴱᴿᴬᵀᴼᴿ' : ''),
            numeric: false,
        })
    }

    const rows = [];

    for (const post of data.posts) {
        const row = {
            post: post['title']['@value'],
        };

        for (const user of data.users) {
            row[user['@id']] = getVoteByUserAndPost(user['@id'], post['@id'], data);
            row[user['@id']] += getProsCountByUserAndPost(user['@id'], post['@id'], data);
            row[user['@id']] -= getConsCountByUserAndPost(user['@id'], post['@id'], data);
        }

        rows.push(row);
    }
    
    // Normalization
    if (normalization) {
        for (const row of rows) {
            for (const user of data.users) {
                row[user['@id']] = Number(ncdf(row[user['@id']], 0, 1).toFixed(4));
            }
        }   
    }

    // for (const argument of data.arguments) {
    //     const row = {
    //         post: 'ARG: ' + argument['title']['@value'],
    //     };
    //
    //     for (const user of data.users) {
    //         row[user['@id']] = getVoteByUserAndArgument(user['@id'], argument['@id'], data);
    //     }
    //
    //     rows.push(row);
    // }

    return {
        rows,
        columns
    }
}

export default getPostsData;
