import React from "react";
import './DataTable.scss';

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from '@material-ui/core/TablePagination';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {exportCSV} from "../../utils/exportCSV";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function DataTable(props) {
    const {title, columns, rows, defaultOrderBy, defaultOrder, loading, onNormalizationChange} = props;

    const [order, setOrder] = React.useState(defaultOrder || 'asc');
    const [orderBy, setOrderBy] = React.useState(defaultOrderBy || columns[0] ? columns[0].id : 0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function handleRequestSort(property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <Paper className="data-table">

            {title ? (<Typography variant="h6" className="data-table__title">
                {title}
            </Typography>) : null}

            <Table size="small" className="data-table__table">
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <TableCell
                                key={column.id}
                                align={column.numeric ? 'right' : 'left'}
                                sortDirection={orderBy === column.id ? order : false}
                                style={{color: column.color}}
                            >
                                <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={order}
                                    onClick={() => handleRequestSort(column.id)}
                                >
                                    {column.label}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {stableSort(rows, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                            <TableRow key={index} className="data-table__row">{
                                columns.map((column, index) => (
                                    <TableCell
                                        key={index}
                                        align={column.numeric ? 'right' : 'left'}>
                                        {row[column.id]}
                                    </TableCell>
                                ))
                            }
                            </TableRow>
                        ))}

                    {emptyRows > 0 && (
                        <TableRow style={{height: 33 * emptyRows}}>
                            <TableCell colSpan={columns.length}/>
                        </TableRow>
                    )}

                </TableBody>

            </Table>

            {loading && (
                <CircularProgress className="data-table__progress"/>
            )}

            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {onNormalizationChange && (
                    <div style={{ marginRight: 'auto' }}>
                        <FormControlLabel
                            control={<Switch onChange={e => onNormalizationChange(e.target.checked)} />} 
                            labelPlacement="start"
                            label="Normalization"
                        />
                    </div>
                )}
                
                <Button
                    variant="text"
                    size="small"
                    onClick={() => exportCSV(columns, rows, title)}
                >
                    Export CSV
                </Button>

                <TablePagination
                    labelRowsPerPage="Rows:"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                /> 
            </div>
            
        </Paper>
    );
}

DataTable.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    defaultOrderBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultOrder: PropTypes.oneOf(['asc', 'desc'])
};

export default DataTable;
