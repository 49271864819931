import React from "react";
import './DataCard.scss';

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

function DataCard(props) {
    const {title, rows, loading} = props;

    return (
        <Paper className="data-card">

            {title ? (<Typography variant="h6" className="data-card__title">
                {title}
            </Typography>) : null}

            {rows.map((row, index) => (
                <div key={index} className="data-card__row">
                    <span className="data-card__label" style={{color: row.color}}>{row.label}</span>
                    {row.url ?
                        <a className="data-card__value" href={row.url} target="_blank" rel="noopener noreferrer">{row.value}</a> :
                        <span className="data-card__value">{row.value}</span>
                        }
                </div>
            ))}

            {loading && (
                <CircularProgress className="data-card__progress"/>
            )}

        </Paper>
    );
}

DataCard.propTypes = {
    title: PropTypes.string,
    rows: PropTypes.array.isRequired,
};

export default DataCard;
