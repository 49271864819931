export function isUserModerator(userID, data) {
    return data.issue.has_creator === userID;
}
export function getPostPros(postID, prosList) {
    return prosList.filter(pro => pro.reply_to === postID);
}

export function getPostCons(postID, consList) {
    return consList.filter(con => con.reply_to === postID);
}

export function getPostVotes(postID, votesList) {
    return votesList.filter(vote => vote.postID === postID);
}

export function getPostPositiveVotes(postID, votesList) {
    return votesList.filter(vote => vote.positive === 'true' && vote.postID === postID);
}

export function getPostNegativeVotes(postID, votesList) {
    return votesList.filter(vote => vote.positive !== 'true' && vote.postID === postID);
}

export function getUserVotes(userID, votesList) {
    return votesList.filter(vote => vote.voter === userID);
}

export function getUserPositiveVotes(userID, votesList) {
    return votesList.filter(item => item.voter === userID && item.positive === 'true');
}

export function getUserNegativeVotes(userID, votesList) {
    return votesList.filter(item => item.voter === userID && item.positive === 'false');
}

export function getViews(userID, eventsList) {
    return eventsList.filter(event => event['@type'] === 'ReadStatusChange');
}

export function getUserViews(userID, eventsList) {
    return eventsList.filter(event => event.who === userID && event['@type'] === 'ReadStatusChange');
}

export function getPostViews(postID, eventsList) {
    return eventsList.filter(event => event.what === postID && event['@type'] === 'ReadStatusChange');
}

export function getUserPosts(userID, postsList) {
    return postsList.filter(item => item.has_creator === userID);
}

export function getUserArguments(userID, argumentsList) {
    return argumentsList.filter(item => item.has_creator === userID);
}

export function getUserPros(userID, prosList) {
    return prosList.filter(item => item.has_creator === userID);
}

export function getUserCons(userID, consList) {
    return consList.filter(item => item.has_creator === userID);
}

export function getUserPostInteractions(userID, postID, data) {
    return {
        cons: data.cons.filter(item => item.reply_to === postID && item.has_creator === userID),
        pros: data.pros.filter(item => item.reply_to === postID && item.has_creator === userID),
        negVotes: data.votes.filter(item => item.positive === 'false' && item.postID === postID && item.voter === userID),
        posVotes: data.votes.filter(item => item.positive === 'true' && item.postID === postID && item.voter === userID),
    }
}

export function getUsersConnections(userIDa, userIDb, data) {
    const userAPosts = getUserPosts(userIDa, data.posts);
    let userBConsForUserA = [];
    let userBProsForUserA = [];
    let userBNegVotesForUserA = [];
    let userBPosVotesForUserA = [];
    for (const post of userAPosts) {
        const {cons, pros, negVotes, posVotes} = getUserPostInteractions(userIDb, post['@id'], data);
        Array.prototype.push.apply(userBConsForUserA, cons);
        Array.prototype.push.apply(userBProsForUserA, pros);
        Array.prototype.push.apply(userBNegVotesForUserA, negVotes);
        Array.prototype.push.apply(userBPosVotesForUserA, posVotes);
    }

    const userBPosts = getUserPosts(userIDb, data.posts);
    let userAConsForUserB = [];
    let userAProsForUserB = [];
    let userANegVotesForUserB = [];
    let userAPosVotesForUserB = [];
    for (const post of userBPosts) {
        const {cons, pros, negVotes, posVotes} = getUserPostInteractions(userIDa, post['@id'], data);
        Array.prototype.push.apply(userAConsForUserB, cons);
        Array.prototype.push.apply(userAProsForUserB, pros);
        Array.prototype.push.apply(userANegVotesForUserB, negVotes);
        Array.prototype.push.apply(userAPosVotesForUserB, posVotes);
    }

    return {
        userBConsForUserA,
        userBProsForUserA,
        userBNegVotesForUserA,
        userBPosVotesForUserA,
        userAConsForUserB,
        userAProsForUserB,
        userANegVotesForUserB,
        userAPosVotesForUserB,
    }
}

export function getVoteByUserAndPost(userID, postID, data) {
    const vote = data.votes.find(vote => vote.voter === userID && vote.postID === postID);
    return vote ? (vote.positive === 'true' ? 1 : -1) : 0;
}

export function getProsCountByUserAndPost(userID, postID, data) {
    return data.pros.filter(pro => pro.has_creator === userID && pro.reply_to === postID).length;
}

export function getConsCountByUserAndPost(userID, postID, data) {
    return data.cons.filter(con => con.has_creator === userID && con.reply_to === postID).length;
}

export function getVoteByUserAndArgument(userID, argumentID, data) {
    const vote = data.votes.find(vote => vote.voter === userID && vote.argumentID === argumentID);
    return vote ? (vote.positive === 'true' ? 1 : 0) : '--';
}

export function getPostCreatorName(postID, data) {
    return data.users.find(user => user['@id'] === data.posts.find(post => post['@id'] === postID).has_creator).name;
}

export function getArgumentCreatorName(argumentID, data) {
    return data.users.find(user => user['@id'] === data.arguments.find(argument => argument['@id'] === argumentID).has_creator).name;
}

export function getPostByArgument(argumentID, data) {
    const pro = data.pros.find(pro => argumentID === pro.argument_arguing);
    const con = data.cons.find(con => argumentID === con.argument_arguing);
    return data.posts.find(post => post['@id'] === (pro || con).reply_to);
}
