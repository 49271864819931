import * as Metrics from './metrics.js';

function getDebateData(data) {
    const posVotes = data.votes.filter(vote => vote.positive === 'true').length;
    const negVotes = data.votes.filter(vote => vote.positive !== 'true').length;
    const updates = data.events.filter(event => event['@type'] === 'Update').length;

    const info = [
        {label: 'Title', value: data.issue.title['@value'], url: data.issue.homepage},
        {label: 'Description', value: data.issue.description ? data.issue.description['@value'] : '--'}
    ];
    const stats = [
        {label: 'Users', value: data.users.length, color: '#800000'},
        {label: 'Posts', value: data.posts.length, color: '#800000'},
        {label: 'Votes', value: `${posVotes}↑ ${negVotes}↓`, color: '#800000'},
        {label: 'Arguments', value: `${data.pros.length}↑ ${data.cons.length}↓`, color: '#800000'},
        {label: 'Edits', value: updates, color: '#800000'},
        {label: 'Text length', value: Number(Metrics.textsLength(data).toFixed(2)), color: '#800000'},
        {label: 'Maturity', value: Number(Metrics.maturity(data).toFixed(2))},
        {label: 'Maturity/Users', value: Number(Metrics.maturityPerUser(data).toFixed(2))},
        {label: 'Reputation+Edits', value: Number(Metrics.reputationAndEditsSum(data).toFixed(2))},
        {label: 'Average incosistence', value: Number(Metrics.averageIncosistence(data).toFixed(2))},
        {label: 'Average reputation', value: Number(Metrics.averageReputation(data).toFixed(2))},
        {label: 'Average efficiency', value: Number(Metrics.averageEfficiency(data).toFixed(2))},
        {label: 'Average controversy 1', value: Number(Metrics.averageControversy1(data).toFixed(2))},
        {label: 'Average conflict', value: Number(Metrics.averageConflict(data).toFixed(2))},
        {label: 'Group Density of social bonds', value: Number(Metrics.groupDensityOfSocialBounds(data).toFixed(2))},
        {label: 'Group Intensity of social bonds', value: Number(Metrics.groupIntensityOfSocialBounds(data).toFixed(2))},
        {label: 'Average interest of community', value: Number(Metrics.averageInterestByCommunity(data).toFixed(2))},
        {label: 'Total Post Views Count', value: Number(Metrics.totalPostViewsCount(data).toFixed(2)), color: '#800000'},
        {label: 'Total Post Views Count/Users', value: Number((Metrics.totalPostViewsCount(data) / data.users.length).toFixed(2))},
    ];

    return {
        info,
        stats
    }
}

export default getDebateData;
