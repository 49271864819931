import * as Metrics from './metrics.js';

function getPostsData(data) {
    const columns = [
        {id: 'title', label: 'Title', numeric: false},
        {id: 'controversy1', label: 'Controversy 1', numeric: true},
        {id: 'interest', label: 'Interest By Community', numeric: true},
        {id:'postViewsCount', label: 'Views Count', numeric: true, color: '#800000'},
        {id:'postViewsCountDividedByUsers', label: 'Views Count / Users', numeric: true},
        {id:'postPosVoteCount', label: 'Positive Vote Count', numeric: true, color: '#800000'},
        {id:'postNegVoteCount', label: 'Negative Vote Count', numeric: true, color: '#800000'},
        {id:'postProsCount', label: 'Pros Count', numeric: true, color: '#800000'},
        {id:'postConsCount', label: 'Cons Count', numeric: true, color: '#800000'},
    ];
    const rows = [];

    for (const post of data.posts) {
        rows.push({
            title: post['title']['@value'],
            controversy1: Number(Metrics.controversy1(post['@id'], data).toFixed(2)),
            interest: Number(Metrics.interestByCommunity(post['@id'], data).toFixed(2)),
            postViewsCount: Number(Metrics.postViewsCount(post['@id'], data).toFixed(2)),
            postViewsCountDividedByUsers: Number((Metrics.postViewsCount(post['@id'], data) / data.users.length).toFixed(2)),
            postPosVoteCount: Number(Metrics.postPosVoteCount(post['@id'], data).toFixed(2)),
            postNegVoteCount: Number(Metrics.postNegVoteCount(post['@id'], data).toFixed(2)),
            postProsCount: Number(Metrics.postProsCount(post['@id'], data).toFixed(2)),
            postConsCount: Number(Metrics.postConsCount(post['@id'], data).toFixed(2)),
        })
    }

    return {
        rows,
        columns
    }
}

export default getPostsData;
