import {
    getViews,
    getPostCons,
    getPostNegativeVotes,
    getPostPositiveVotes,
    getPostPros,
    getPostViews,
    getPostVotes,
    getUserPositiveVotes,
    getUserNegativeVotes,
    getUserArguments,
    getUserCons,
    getUserPosts,
    getUserPros,
    getUsersConnections,
    getUserViews,
    getUserVotes
} from "./dataUtils";

export function controversy1(postID, data) {
    const prosCount = getPostPros(postID, data.pros).length;
    const consCount = getPostCons(postID, data.cons).length;
    const posVoteCount = getPostPositiveVotes(postID, data.votes).length;
    const negVoteCount = getPostNegativeVotes(postID, data.votes).length;

    return consCount + negVoteCount + Math.min(prosCount, consCount) + Math.min(posVoteCount, negVoteCount);
}

export function controversy2(postID, data) {
    const prosCount = getPostPros(postID, data.pros).length;
    const consCount = getPostCons(postID, data.cons).length;
    const posVoteCount = getPostPositiveVotes(postID, data.votes).length;
    const negVoteCount = getPostNegativeVotes(postID, data.votes).length;

    return consCount + negVoteCount + 0.33*prosCount + 0.33*posVoteCount;
}

export function interestByCommunity(postID, data) {
    const postVotesCount = getPostVotes(postID, data.votes).length;
    const postProsCount = getPostPros(postID, data.pros).length;
    const postConsCount = getPostCons(postID, data.cons).length;
    const postViewsCount = getPostViews(postID, data.events).length;

    return (postViewsCount + postVotesCount + postProsCount + postConsCount) / data.users.length;
}

export function averageInterestByCommunity(data) {
   return data.posts.reduce((agg, post) => agg + interestByCommunity(post['@id'], data), 0) / data.posts.length;
}

export function reputation(userID, data) {
    const userPosts = getUserPosts(userID, data.posts);
    let userPostsPros = 0;
    let userPostsCons = 0;
    let userPostsPosVotes = 0;
    let userPostsNegVotes = 0;

    for (const post of userPosts) {
        userPostsPosVotes += getPostPositiveVotes(post['@id'], data.votes).length;
        userPostsNegVotes += getPostNegativeVotes(post['@id'], data.votes).length;
        userPostsPosVotes += getPostPros(post['@id'], data.pros).length;
        userPostsNegVotes += getPostCons(post['@id'], data.cons).length;
    }

    return (userPostsPosVotes - userPostsNegVotes) + (userPostsPros - userPostsCons);
}

export function reputationAndEditsSum(data) {
    let sum = 0;

    for (const user of data.users) {
        if (data.issue.has_creator !== user['@id']) {  // Omit edits if moderator
            sum += userEdits(user['@id'], data);
        }

        sum += reputation(user['@id'], data);
    }

    return sum;
}

export function involvement(userID, data) {
    const allContribution = data.posts.length + data.arguments.length;

    if (allContribution === 0) return 0;

    const userPostsCount = getUserPosts(userID, data.posts).length;
    const userArgumentsCount = getUserArguments(userID, data.arguments).length;

    const userTextContribution = userPostsCount + userArgumentsCount;

    return (userTextContribution / allContribution) * 100;
}

export function efficiency(userID, data) {
    const userPostsCount = getUserPosts(userID, data.posts).length;
    const userArgumentsCount = getUserArguments(userID, data.arguments).length;
    const userVotesCount = getUserVotes(userID, data.votes).length;
    const userViewsCount = getUserViews(userID, data.events).length;

    return ((0.5*userPostsCount + 0.3*userArgumentsCount + 0.2*userVotesCount) / userViewsCount) * 100;
}

export function textsLength(data) {
    const { posts, arguments: args } = data;

    let textsLength = 0;

    for (const post of posts) {
        if (post.title) {
            textsLength += post.title['@value'].length;
        }
        if (post.description) {
            textsLength += post.description['@value'].length;
        }
    }

    for (const argument of args) {
        if (argument.title) {
            textsLength += argument.title['@value'].length;
        }
        if (argument.description) {
            textsLength += argument.description['@value'].length;
        }
    }

    return textsLength;
}

export function userTextsLength(userID, data) {
    const userPosts = getUserPosts(userID, data.posts);
    const userArguments = getUserArguments(userID, data.arguments);

    let textsLength = 0;

    for (const post of userPosts) {
        if (post.title) {
            textsLength += post.title['@value'].length;
        }
        if (post.description) {
            textsLength += post.description['@value'].length;
        }
    }

    for (const argument of userArguments) {
        if (argument.title) {
            textsLength += argument.title['@value'].length;
        }
        if (argument.description) {
            textsLength += argument.description['@value'].length;
        }
    }

    return textsLength;
}

export function maturity(data) {
    const updatesCount = data.events.filter(event => event['@type'] === 'Update').length;

    return data.posts.length + data.pros.length + data.cons.length + 0.3 * updatesCount;
}

export function maturityPerUser(data) {
    return maturity(data) / data.users.length;
}

export function inconsistentSupport(userID, data) {
    let i10tSupports = 0;

    const userVotes = getUserVotes(userID, data.votes);
    const userPros = getUserPros(userID, data.pros);
    const userCons = getUserCons(userID, data.cons);

    for (const post of data.posts) {
        const prosCount = getPostPros(post['@id'], userPros).length;
        const consCount = getPostCons(post['@id'], userCons).length;
        const voteUpCount = getPostPositiveVotes(post['@id'], userVotes).length;
        const voteDownCount = getPostNegativeVotes(post['@id'], userVotes).length;

        if ((prosCount > 0 && voteDownCount > 0) ||
            (consCount > 0 && voteUpCount > 0)) {
            i10tSupports++;
        }
    }

    return i10tSupports;
}

export function userEdits(userID, data) {
    return data.events.filter((event) => event['@type'] === 'Update' && event.who === userID).length;
}

export function averageReputation(data) {
    return data.users.reduce((acc, item) => acc + reputation(item['@id'], data), 0) / data.users.length;
}

export function averageEfficiency(data) {
    return data.users.reduce((acc, item) => acc + efficiency(item['@id'], data), 0) / data.users.length;
}

export function averageIncosistence(data) {
    return data.users.reduce((acc, item) => acc + inconsistentSupport(item['@id'], data), 0) / data.users.length;
}

export function averageControversy1(data) {
    return data.posts.reduce((acc, item) => acc + controversy1(item['@id'], data), 0) / data.posts.length;
}

export function averageControversy2(data) {
    return data.posts.reduce((acc, item) => acc + controversy2(item['@id'], data), 0) / data.posts.length;
}

export function conflict(userID, data) {
    let conflict = 0;

    for (const user of data.users) {
        if (user['@id'] === userID) continue;

        const {
            userAConsForUserB,
            userAProsForUserB,
            userANegVotesForUserB,
            userAPosVotesForUserB,
            userBConsForUserA,
            userBProsForUserA,
            userBNegVotesForUserA,
            userBPosVotesForUserA,
        } = getUsersConnections(userID, user['@id'], data);

        conflict += (userAConsForUserB.length - userAProsForUserB.length)
          + (userANegVotesForUserB.length - userAPosVotesForUserB.length)
          + (userBConsForUserA.length - userBProsForUserA.length)
          + (userBNegVotesForUserA.length - userBPosVotesForUserA.length);
    }

    return conflict;
}

export function averageConflict(data) {
    return data.users.reduce((acc, item) => acc + conflict(item['@id'], data), 0) / data.users.length;
}

export function densityOfSocialBounds(userID, data) {
    if (data.users.length < 2) return 0;

    let connectedUsersCount = 0;

    for (const user of data.users) {
        if (user['@id'] === userID) continue;

        const {
            userAConsForUserB,
            userAProsForUserB,
            userANegVotesForUserB,
            userAPosVotesForUserB,
            userBConsForUserA,
            userBProsForUserA,
            userBNegVotesForUserA,
            userBPosVotesForUserA,
        } = getUsersConnections(userID, user['@id'], data);

        const connectionsCount = userAConsForUserB.length + userAProsForUserB.length
          + userANegVotesForUserB.length + userAPosVotesForUserB.length
          + userBConsForUserA.length + userBProsForUserA.length
          + userBNegVotesForUserA.length + userBPosVotesForUserA.length;

        if (connectionsCount > 0) {
            connectedUsersCount++;
        }
    }

    return connectedUsersCount / (data.users.length - 1);
}

export function groupDensityOfSocialBounds(data) {
    return data.users.reduce((acc, item) => acc + densityOfSocialBounds(item['@id'], data), 0) / data.users.length;
}

export function intensityOfSocialBounds(userID, data) {
    if (data.users.length < 2) return 0;

    let connectionsCount = 0;

    for (const user of data.users) {
        if (user['@id'] === userID) continue;

        const {
            userAConsForUserB,
            userAProsForUserB,
            userANegVotesForUserB,
            userAPosVotesForUserB,
            userBConsForUserA,
            userBProsForUserA,
            userBNegVotesForUserA,
            userBPosVotesForUserA,
        } = getUsersConnections(userID, user['@id'], data);

        connectionsCount += userAConsForUserB.length + userAProsForUserB.length
          + userANegVotesForUserB.length + userAPosVotesForUserB.length
          + userBConsForUserA.length + userBProsForUserA.length
          + userBNegVotesForUserA.length + userBPosVotesForUserA.length;
    }

    return connectionsCount / (data.users.length - 1);
}

export function groupIntensityOfSocialBounds(data) {
    return data.users.reduce((acc, item) => acc + intensityOfSocialBounds(item['@id'], data), 0) / data.users.length;
}

export function activity(userID, data) {
    const userPostsCount = getUserPosts(userID, data.posts).length;
    const userArgumentsCount = getUserArguments(userID, data.arguments).length;
    const userVotesCount = getUserVotes(userID, data.votes).length;
    const userViewsCount = getUserViews(userID, data.events).length;
    return 0.4 * userPostsCount + 0.25 * userArgumentsCount + 0.2*userVotesCount + 0.15*userViewsCount / 100;
}

export function totalPostViewsCount(data) {
    return data.posts.reduce((sum, post) => sum + getPostViews(post['@id'], data.events).length, 0);
}

export function userViewsCount(userID, data) {
    return getUserViews(userID, data.events).length;
}

export function userPostsCount(userID, data) {
    return getUserPosts(userID, data.posts).length;
}

export function userProsCount(userID, data) {
    return getUserPros(userID, data.pros).length;
}

export function userConsCount(userID, data) {
    return getUserCons(userID, data.pros).length;
}

export function userPositiveVotesCount(userID, data) {
    return getUserPositiveVotes(userID, data.votes).length;
}

export function userNegativeVotesCount(userID, data) {
    return getUserNegativeVotes(userID, data.votes).length;
}

export function postViewsCount(postID, data) {
    return getPostViews(postID, data.events).length;
}

export function postVoteCount(postID, data) {
    return getPostVotes(postID, data.votes).length;
}

export function postPosVoteCount(postID, data) {
    return getPostPositiveVotes(postID, data.votes).length;
}

export function postNegVoteCount(postID, data) {
    return getPostNegativeVotes(postID, data.votes).length;
}

export function postProsCount(postID, data) {
    return getPostPros(postID, data.pros).length;
}

export function postConsCount(postID, data) {
    return getPostCons(postID, data.cons).length;
}
