/**
 * Average of array of values
 * @param values
 * @returns {number}
 */
export function average(values) {
    const sum = values.reduce((acc, v) => acc + v, 0);
    return sum / values.length;
}

export function variance(values, mean) {
    mean = (mean === undefined) ? average(values) : mean;
    const n = values.length;

    let dev = 0;

    for (let i = n; i--;) {
        dev += Math.pow(values[i] - mean, 2);
    }

    return dev / n;
}

export function stdDeviation(values, vrnc) {
    vrnc = (vrnc === undefined) ? variance(values) : vrnc;

    return Math.sqrt(vrnc);
}

/**
 * Normal cumulative distribution function
 * @param x
 * @param mean
 * @param std
 * @returns {number}
 */
export function ncdf(x, mean = 0, std = 1) {
    x = (x - mean) / std;
    const t = 1 / (1 + .2315419 * Math.abs(x));
    const d = .3989423 * Math.exp(-x * x / 2);
    let prob = d * t * (.3193815 + t * (-.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));

    if (x > 0) prob = 1 - prob

    return prob;
}
