import React, {useState} from 'react';
import './App.scss';

import logo from '../../logo.svg';

import {Container} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DataTable from "../DataTable/DataTable";
import DataCard from "../DataCard/DataCard";

import {CIFdataLimiter, CIFparser, CIFparserLimiting} from '../../lib/CIFparser';
import getUsersData from "../../lib/getUsersData";
import getPostsData from "../../lib/getPostsData";
import getVotesData from "../../lib/getVotesData";
import getRelationsData from "../../lib/getRelationsData";
import getDebateData from "../../lib/getDebateData";

function App() {
    const [parsedData, setParsedData] = useState(null);
    const [usersData, setUsersData] = useState({columns: [], rows: []});
    const [postsData, setPostsData] = useState({columns: [], rows: []});
    const [votesData, setVotesData] = useState({columns: [], rows: []});
    const [relationsData, setRelationsData] = useState({columns: [], rows: []});
    const [debateData, setDebateData] = useState({info: [], stats: []});
    const [usersLimit, setUsersLimit] = useState(null);

    const [dataLoading, setDataLoading] = useState(false);

    async function fetchJSON(url) {
        const response = await fetch(url, {
            mode: 'cors',
            cache: 'no-cache'
        });

        if (!response.ok) {
            throw Error(response.statusText);
        }

        return response.json();
    }

    async function handleUrlChange(e) {
        const url = e.target.value.trim();

        if (!url) {
            return;
        }

        try {
            setDataLoading(true);
            const rawData = await fetchJSON(e.target.value);
            let data = CIFparser(rawData);
            if (usersLimit) {
                data = CIFdataLimiter(data, usersLimit);
            }
            setParsedData(data);
            setDataLoading(false);
            setDebateData(getDebateData(data));
            setUsersData(getUsersData(data));
            setPostsData(getPostsData(data));
            setVotesData(getVotesData(data));
            setRelationsData(getRelationsData(data));
        } catch (e) {
            console.error('App error:', e);
            alert(e.message);
        }
    }

    async function handleUsersLimitChange(e) {
        const limit = e.target.value;
        setUsersLimit(limit);

        if (!parsedData) {
            return;
        }

        try {
            let data = CIFparser(parsedData.raw);
            if (limit) {
                data = CIFdataLimiter(data, limit);
            }

            setParsedData(data);
            setDebateData(getDebateData(data));
            setUsersData(getUsersData(data));
            setPostsData(getPostsData(data));
            setVotesData(getVotesData(data));
            setRelationsData(getRelationsData(data));
        } catch (e) {
            console.error('App error:', e);
            alert(e.message);
        }
    }

    return (
        <Container maxWidth="md" className="app">
            <div className="app__header">
                <img src={logo} className="app__logo" alt="logo" />
                <Typography variant="h1" className="app__title">
                    Collective Intelligence Laboratory / CI Metrics & Analytics / Aurea Libertas Institute
                </Typography>
            </div>

            <TextField
                id="analyticsUrl"
                label="URL to CIF data"
                margin="normal"
                fullWidth
                onChange={handleUrlChange}
            />

            <TextField
                label="Users limit (number of most involved users)"
                type="number"
                InputProps={{
                    inputProps: {
                        min: 1,
                    }
                }}
                fullWidth
                value={usersLimit}
                onChange={handleUsersLimitChange}
            />

            <Grid container spacing={3} style={{marginTop: '0'}} component="div">
                <Grid item sm={6} xs={12} component="div">
                    <DataCard title="Debate" rows={debateData.info} loading={dataLoading} />
                </Grid>
                <Grid item sm={6} xs={12} component="div">
                    <DataCard title="Debate Statistics" rows={debateData.stats} loading={dataLoading} />
                </Grid>
            </Grid>

            <DataTable title="Users" columns={usersData.columns} rows={usersData.rows} loading={dataLoading} />
            <DataTable title="Posts" columns={postsData.columns} rows={postsData.rows} loading={dataLoading} />
            <DataTable title="Votes" columns={votesData.columns} rows={votesData.rows} loading={dataLoading}
                       onNormalizationChange={normalize => setVotesData(getVotesData(parsedData, normalize))}/>
            <DataTable title="Social network analysis" columns={relationsData.columns} rows={relationsData.rows} loading={dataLoading} />
        </Container>
    );
}

export default App;
